
import $axios from '@/utils/request'


/**
 * 添加商户
 * @param {*} data
 * @returns
 */

export function $addMerchant(data) {
  const url = '/merchant/addMerchant'
  return $axios.fPost(url, data)
}

/**
 * 添加员工
 * @param {*} data
 * @returns
 */
 export function addMerchantEmployee(data) {
  const url = '/merchant/addMerchantEmployee'
  return $axios.fPost(url, data)
}
/**
 *禁用员工1
 * @param {*} data
 * @returns
 */

 export function deleteEmployee(data) {
  const url = '/merchant/deleteEmployee'
  return $axios.fPut(url, data)
}


/**
 * 更改员工
 * @param {*} data
 * @returns
 */

 export function editEmployee(data) {
  const url = '/merchant/editEmployee'
  return $axios.fPost(url, data)
}
/**
 * 更改商户
 * @param {*} data
 * @returns
 */
export function $editMerchant(data) {
  const url = '/merchant/editMerchant'
  return $axios.fPost(url, data)
}

/**
 *禁用员工2
 * @param {*} data
 * @returns
 */
 export function forbiddenEmployee(data) {
  const url = '/merchant/forbiddenEmployee'
  return $axios.fPut(url, data)
}


/**
 *禁用商户
 * @param {*} data
 * @returns
 */
export function forbiddenMerchant(data) {
  const url = '/merchant/forbiddenMerchant'
  return $axios.fPut(url, data)
}








/**
 * 获取员工基本信息
 * @param {*} data
 * @returns
 */
 export function $getEmployeeInfo(data) {
  const url = '/merchant/getEmployeeInfo'
  return $axios.fGet(url, data)
}





/**
 * 获取用户列表
 * @param {*} data
 * @returns
 */

export function getEmployeeList(data) {
  const url = '/merchant/getEmployeeList'
  return $axios.fGet(url, data)
}



/**
 * 获取商户基本信息
 * @param {*} data
 * @returns
 */

 export function $getInfo(data) {
  const url = '/merchant/getInfo'
  return $axios.fGet(url, data)
}

/**
 * 获取商户列表
 * @param {*} data
 * @returns
 */


 export function getMerchantList(data) {
  const url = '/merchant/getMerchantList'
  return $axios.fGet(url, data)
}

/**
 * 获取学员列表
 * @param {*} data
 * @returns
 */
 export function getStudentList(data) {
  const url = '/merchant/getStudentList'
  return $axios.fGet(url, data)
}










export function listMerchantCourseGoods(data) {
  const url = '/merchantCourse/listMerchantCourseGoods'
  return $axios.fGet(url, data)
}

//商户二级树
export function getTreeCourseTwo(data) {
  const url = '/course/getTreeCourseTwo'
  return $axios.fGet(url, data)
}

//商户已授权课程列表
export function listMerchantCourse(data) {
  const url = '/merchantCourse/listMerchantCourse'
  return $axios.fPost(url, data)
}

export function buyCourseGoods(data) {
  const url = '/merchantCourse/buyCourseGoods'
  return $axios.fPost(url, data)
}

export function deleteCourseGoods(data) {
  const url = '/merchantCourse/deleteCourseFoods'
  return $axios.fPost(url, data)
}
export function distributeCourse(data) {
  const url = '/merchantCourse/distributeCourse'
  return $axios.fPost(url, data)
}

export function distributeCourseOnBuyGoods(data) {
  const url = 'merchantCourse/distributeCourseOnBuyGoods'
  return $axios.fPost(url, data)
}

export function listDistributedCourse(data) {
  const url = '/merchantCourse/listDistributedCourse'
  return $axios.fGet(url, data)
}
// 三级课程结构 课节
export function getTreeCourseThird(data) {
  const url = '/course/getTreeCourseThird'
  return $axios.fPost(url, data)
}
// 获取用户分配得备授课列表
export function listCoursePreparation(data) {
  const url = '/coursePreparation/list'
  return $axios.fGet(url, data)
}
// 备授课-批量开放
export function addCoursePreparationUser(data) {
  const url = '/coursePreparation/addCoursePreparationUser'
  return $axios.fPost(url, data)
}

// 获取用户课程列表
export function getUserCourseListByUser(data) {
  const url = '/course/user/getUserCourseListByUser'
  return $axios.fGet(url, data)
}

// 修改用户培训使用状态
export function batchCourseUserByUser (data) {
  const url = '/course/user/batchCourseUserByUser'
  return $axios.fPost(url, data)
}


